export default {
  mapStyle: [
    {},
    {},
    {},
    {},
    {},
    {
      featureType: "water",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#e7c8be",
        },
      ],
    },
    {
      featureType: "road.highway.controlled_access",
      elementType: "geometry",
      stylers: [
        {
          color: "#e7c8be",
          visibility: "off",
        },
      ],
    },
  ],
};
