import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../layouts/home';
import SEO from '../components/SEO';

import contentParser from 'gatsby-wpgraphql-inline-images';
import { inlineUrls } from '../utils';
import WorkWithUs from '../components/Forms/workwithus';
// import GoogleMap from "../components/Map";
import Location from '../components/Map/location';

class Contact extends React.Component {
  render() {
    const {
      location,
      data: {
        wordPress: {
          pageBy: {
            title,
            acf_subtitle: { subtitle },
            excerpt,
            content,
          },
        },
      },
    } = this.props;

    // console.log(this.props);

    return (
      <Layout location={location} cssClass='about'>
        <SEO title={title} description={excerpt} />
        <section id='header' className='p-top p-bottom text-center bg-pink'>
          <div className='featured'>
            <h1>{title}</h1>
            <div className='lead'>
              <p>{subtitle}</p>
            </div>
          </div>
        </section>
        <section id='body' className='bg-charcoal b-top b-bottom'>
          <div className='container-full'>
            <div className='row align-items-center'>
              <div className='col-md-6 h-full'>
                <Location />
              </div>
              <div className='col-md-4 p-top p-bottom p-left'>
                <h2>{title}</h2>
                <div>{contentParser({ content }, inlineUrls)}</div>
              </div>
            </div>
          </div>
        </section>
        <WorkWithUs />
      </Layout>
    );
  }
}

export default Contact;

export const contactQuery = graphql`
  {
    wordPress {
      pageBy(uri: "contact") {
        title
        acf_subtitle {
          subtitle
        }
        excerpt
        content
      }
    }
  }
`;
